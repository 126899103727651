<template>

  <router-view/>

</template>

<style lang="scss">
body{
  margin: 0;
  padding: 0;
  background: rgba(247, 247, 247, 1);
}
#app{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.flex_center{
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex_start{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.flex_end{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.flex_around{
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.flex_between{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
