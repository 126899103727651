import { createRouter, createWebHashHistory } from 'vue-router'


const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import( '../pages/index/index.vue'),
    meta:{
      keepAlive:false,
      title:'导航网址'
    }
  }

]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
